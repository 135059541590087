import HttpRequest from "./HttpRequest";
import Api from "../constants/Api";

export const getUserPolicies = (payload) => {
   return HttpRequest.get(Api.userPolicies, payload);
};

export const getUserContactStageProducts = (payload) => {
  return HttpRequest.get(`${Api.userContactStageProducts}/${payload}`);
};

export const updateSuccessDealPolicy = (payload) => {
  return HttpRequest.put(Api.updateSuccessDealPolicy, payload);
};