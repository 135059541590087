import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {
  BasicAddButton,
  BasicFormLabel,
  BasicInput,
  ContentWrapper
} from "../GlobalAddDealModalV3/GlobalAddDealModalStyles";
import BasicSelect from "../GlobalAddDealModalV3/components/BasicSelect";
import CustomTitleAddDeal from "../GlobalAddDealModalV3/components/CustomTitleAddDeal";
import Loading from "./Loading";
import Utils from "../../helpers/Utils";
import {CloseIcon} from "./Icons";
import {getInsuranceCompanies} from "../../api/tenantApi";
import {getUserContactStageProducts, getUserPolicies, updateSuccessDealPolicy} from "../../api/dealApi";

const modalStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 930,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
  },
  modalHeader: {
    padding: "16px",
    background: "#346fef",
    color: "#fff",
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .closeIcon": {
      display: "flex",
      cursor: "pointer",
      "& svg": {
        color: "#fff",
        backgroundColor: "var(--red)",
        width: "25px",
        height: "25px",
        borderRadius: "50px"
      },
    },
  },
  modalBody: {
    padding: "16px",
    "& .productValueSignDisabled": {
      color: "rgba(0,0,0,0.42)"
    }
  },
  modalBodyInput: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },

  modalFooter: {
    background: "rgb(24, 31, 72)",
    borderEndEndRadius: "8px",
    borderEndStartRadius: "8px",
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& .title": {
      color: "#fff",
      fontWeight: "600",
    },

    "& .btnSubmit": {
      background: "#346fef !important",
      fontWeight: "600"
    },
  },
  errorText: {
    color: "red",
    marginTop: "4px",
    fontSize: "0.875rem",
  },
}));

const formatDateForInput = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const ConfirmDealModal = ({ open, onClose, contact_stages_id, data }) => {
  const classes = modalStyles();
  const today = new Date();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formattedDate = formatDateForInput(today);
  const [title, setTitle] = useState("");
  const [dealValue, setDealValue] = useState(0);
  const [dealCloseDate, setDealCloseDate] = useState(formattedDate);
  const [userPolicies, setUserPolicies] = useState([]);
  const [userPolicyCompanies, setUserPolicyCompanies] = useState([]);
  const [contactStagePolicies, setContactStagePolicies] = useState([]);
  const [errors, setErrors] = useState({
    title: "",
    deal_closed_date: "",
    deal_value: "",
    user_policy_id: "",
    insurance_company_id: "",
    userPolicies: [],
  });

  useEffect(() => {
    (async ()=>{
      try{
        setIsLoading(true);
        const [
          resp1,
          resp2,
          resp3
        ] =
            await Promise.all([
              getInsuranceCompanies(),
              getUserPolicies(),
              getUserContactStageProducts(contact_stages_id)
            ]);

        if (resp1 && resp1.success){
          setUserPolicyCompanies(resp1.data);
        }else {
          setUserPolicyCompanies([]);
        }

        if (resp2 && resp2.success){
          setUserPolicies(resp2.data);
        }

        if (resp3.success) {
          setTitle(resp3.data.contactStage?.title);
          const closeDate = resp3.data.contactStage?.estimate_closing_date?.split(" ")[0];
          setDealCloseDate(closeDate || formattedDate);
          setDealValue(resp3.data.contactStage?.deal_value);

          setContactStagePolicies(resp3.data.policies);
        }
      }catch (err){

      }finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (contactStagePolicies[0]) {
      let productSum = contactStagePolicies
          .reduce((accumulator, currentObject) => (accumulator + (parseFloat(currentObject.value) || 0)), 0);
      setDealValue(productSum)
    }
  }, [contactStagePolicies]);

  const handleAddPolicy = () => {
    let old = [...contactStagePolicies];
    
    old.push({
      user_policy_id: "",
      insurance_company_id: "",
      value: 0,
      effective_date: formattedDate,
    });

    setContactStagePolicies(old);

    setErrors((prevErrors) => ({
      ...prevErrors,
      userPolicies: [
          ...prevErrors.userPolicies,
        {
          user_policy_id: "",
          insurance_company_id: "",
          value: 0,
          effective_date: "",
        }
      ],
    }));
  };

  const handleAnnualizedPremiumChange = (index, value) => {
    let old = [...contactStagePolicies];
    let errorCopy = [...errors.userPolicies];

    if (typeof value === "string" && value.trim() !== "") {
      if (value.length > 10) {
        return;
      }

      const regex = /^[0-9]*\.?[0-9]*$/;

      if (!regex.test(value)){
        return;
      }

      if (value === ".") {
        value = "0.";
      }

      let parsedDealValue = dealValue;
      const selectedValue = value;

      if (old[index]["value"] === 0) {
        if (parsedDealValue + selectedValue >= 0) {
          if(old?.length===1){
            parsedDealValue=0;
          }
          let temDealValue = parseFloat(parsedDealValue + selectedValue);
          setDealValue(temDealValue);
        } else {
          setDealValue(0);
        }
      } else if (old[index]["value"] >= selectedValue) {
        if (parsedDealValue - parseFloat(old[index]["value"]) + selectedValue >= 0) {
          let temDealValue = parseFloat(parsedDealValue + old[index]["value"] + selectedValue);
          setDealValue( temDealValue);
        } else {
          setDealValue(0);
        }
      } else {
        if (!old[index]["value"]) {
          if (parsedDealValue + selectedValue >= 0) {
            let temDealValue = parseFloat(parsedDealValue + selectedValue);
            setDealValue(temDealValue);
          } else {
            setDealValue(0);
          }
        } else {
          if (parsedDealValue + selectedValue - parseFloat(old[index]["value"]) >= 0) {
            let temDealValue = parseFloat(parsedDealValue + selectedValue) - old[index]["value"];
            setDealValue(temDealValue);
          } else {
            setDealValue(0);
          }
        }
      }
    }

    old[index]["value"] = value;
    setContactStagePolicies(old);
    setErrors((prevErrors) => ({ ...prevErrors, userPolicies: errorCopy }));
  };

  const handleUserPolicyIdChange = (index, value) => {
    let old = [...contactStagePolicies];
    let errorCopy = [...errors.userPolicies];

    old[index]["user_policy_id"] = value;

    if (value === "") {
      if (errorCopy[index]) {
        errorCopy[index].user_policy_id = "Policy cannot be empty";
      }
    } else {
      if (errorCopy[index]) {
        errorCopy[index].user_policy_id = "";
      }
    }

    setContactStagePolicies(old);
    setErrors((prevErrors) => ({ ...prevErrors, userPolicies: errorCopy }));
  };

  const handleInsuranceCompanyIdChange = (index, value) => {
    let old = [...contactStagePolicies];
    let errorCopy = [...errors.userPolicies];

    old[index]["insurance_company_id"] = value;

    if (value === "") {
      if (errorCopy[index]) {
        errorCopy[index].insurance_company_id = "Insurance company cannot be empty";
      }
    } else {
      if (errorCopy[index]) {
        errorCopy[index].insurance_company_id = "";
      }
    }

    setContactStagePolicies(old);
    setErrors((prevErrors) => ({ ...prevErrors, userPolicies: errorCopy }));
  };

  const handleEffectiveDateChange = (index, value) => {
    let old = [...contactStagePolicies];
    let errorCopy = [...errors.userPolicies];

    old[index]["effective_date"] = value;

    if (value === "") {
      if (errorCopy[index]) {
        errorCopy[index].effective_date = "Effective date cannot be empty";
      }
    } else {
      if (errorCopy[index]) {
        errorCopy[index].effective_date = "";
      }
    }

    setContactStagePolicies(old);
    setErrors((prevErrors) => ({ ...prevErrors, userPolicies: errorCopy }));
  };

  const handleDeletePolicy = (index) => {
    let old = [...contactStagePolicies];
    let errorCopy = [...errors.userPolicies];

    old.splice(index, 1);
    errorCopy.splice(index, 1);

    setContactStagePolicies(old);
    setErrors((prevErrors) => ({ ...prevErrors, userPolicies: errorCopy }));
  };

  const validateForm = () => {
    let formValid = true;
    let errors = { title: "", deal_value: "", userPolicies: [] };

    contactStagePolicies.forEach((product, index) => {
      if (product?.user_policy_id === "") {
        errors.userPolicies[index] = { user_policy_id: "Policy cannot be empty" };
        formValid = false;
      }
    });

    setErrors(errors);
    return formValid;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const payload = {
      title: title,
      deal_closed_date: window.globalTimezoneConversionToDifferentTimezone(dealCloseDate, Utils.getAccountData("userTimezoneRaw"), 'UTC', 'YYYY-MM-DD HH:mm:ss'),
      deal_value: dealValue,
      user_policies: contactStagePolicies,
      contact_stage_id: contact_stages_id
    };

    try{
      setIsSubmitting(true);
      const reps = await updateSuccessDealPolicy(payload);
      if (reps.success) {
        data?.callback({ success: true });
        window.showNotification("SUCCESS", reps.message);
        onClose();
      } else {
        window.showNotification("ERROR", reps.message);
        onClose();
      }
    }catch (err){
      console.log(err)
    }finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <Typography style={{
              height: 'unset !important', color: 'white', fontWeight: 600,
            }} variant="h5">Confirm Your Deal Information</Typography>

            <div className="closeIcon" onClick={onClose}>
              <CloseIcon />
            </div>
          </div>

          <div className={classes.modalBody}>
            {
              isLoading ?
                <Loading /> :
                <ContentWrapper>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography style={{ height: 'unset !important', fontWeight: 600, color: "#133159" }} className={errors.title ? classes.errorText : ""}>
                        Deal Title
                      </Typography>
                      <div>
                        <BasicInput
                          fullWidth
                          value={title}
                          onChange={(e) => { setTitle(e.target.value); validateForm(); }}
                          required
                          className={errors.title ? classes.errorStyle : ""}
                        />
                        {errors.title && (
                          <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                            {errors.title}
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography style={{ height: 'unset !important', fontWeight: 600, color: "#133159" }}>Close Date</Typography>
                      <div>
                        <BasicInput
                            type="date"
                            fullWidth
                            value={dealCloseDate}
                            onChange={(e) => { setDealCloseDate(e.target.target); }}
                        />
                        {errors.deal_closed_date && (
                            <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                              {errors.deal_closed_date}
                            </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography style={{ height: 'unset !important', fontWeight: 600, color: "#133159" }}>Deal Value</Typography>
                      <div className="productValueInputWrapper">
                        <span className={`productValueSign ${dealValue ? "" : "productValueSignDisabled"}`}>$</span>
                        <BasicInput
                            name="dealValue"
                            value={dealValue}
                            fullWidth
                            onChange={(e) => { setDealValue(e.target.value); }}
                        />
                        {errors.deal_value && (
                            <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                              {errors.deal_value}
                            </Typography>
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid
                    style={{ maxHeight: "calc(100vh - 400px)", overflowY: "auto", marginTop: 16 }}
                    container
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <CustomTitleAddDeal
                        title={"Lines of business associated with the deal:"}
                      />
                    </Grid>

                    <Grid
                      container
                      style={{ marginTop: "4px", padding: "0px 8px" }}
                    >
                      {contactStagePolicies.length > 0 && (
                        <>
                          <Grid item xs={3}>
                            <BasicFormLabel variant="body1">Policy Type</BasicFormLabel>
                          </Grid>

                          <Grid item xs={3} style={{ paddingLeft: "7px" }}>
                            <BasicFormLabel variant="body1">Insurance Company</BasicFormLabel>
                          </Grid>

                          <Grid item xs={3} style={{ paddingLeft: "10px" }}>
                            <BasicFormLabel variant="body1">Annualized Premium</BasicFormLabel>
                          </Grid>

                          <Grid item xs={3} style={{ paddingLeft: "14px" }}>
                            <BasicFormLabel variant="body1">Effective Date</BasicFormLabel>
                          </Grid>
                        </>
                      )}

                      {contactStagePolicies?.map((product, index) => (
                        <Grid
                          container
                          spacing={2}
                          className="productItem"
                          key={index}
                        >
                          <Grid item xs={3}>
                            <BasicSelect
                              name="user_policy_id"
                              options={userPolicies}
                              mapping={{
                                label: "title",
                                value: "id",
                              }}
                              deletedMapping={{
                                field: "status",
                                value: "TRASH",
                              }}
                              defaultText="Select policy"
                              value={product.user_policy_id}
                              onChange={(e) => {
                                handleUserPolicyIdChange(
                                  index,
                                  e.target.value
                                );
                              }}
                              selectedOption={(option) => {
                                handleAnnualizedPremiumChange(
                                  index,
                                  option.unit_price
                                );
                              }}
                            />
                            {errors.userPolicies[index]?.user_policy_id && (
                              <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                                {errors.userPolicies[index]?.user_policy_id}
                              </Typography>
                            )}
                          </Grid>

                          <Grid item xs={3}>
                            <BasicSelect
                                name="insurance_company_id"
                                options={userPolicyCompanies}
                                mapping={{
                                  label: "company_name",
                                  value: "id",
                                }}
                                deletedMapping={{
                                  field: "status",
                                  value: "TRASH",
                                }}
                                defaultText="Select company"
                                value={product.insurance_company_id}
                                onChange={(e) => {
                                  handleInsuranceCompanyIdChange(
                                      index,
                                      e.target.value
                                  );
                                }}
                            />
                            {errors.userPolicies[index]?.user_policy_id && (
                                <Typography style={{ height: 'unset !important' }} variant="body2" className={classes.errorText}>
                                  {errors.userPolicies[index]?.user_policy_id}
                                </Typography>
                            )}
                          </Grid>

                          <Grid item xs={3}>
                            <div className="productValueInputWrapper">
                              <span className={`productValueSign${product.user_policy_id === '' ? " productValueSignDisabled" : ""}`}>$</span>
                              <BasicInput
                                  name="value"
                                  value={product.value}
                                  fullWidth
                                  disabled={product.user_policy_id === '' ? true : false}
                                  onChange={(e) => {
                                    handleAnnualizedPremiumChange(
                                        index,
                                        e.target.value
                                    );
                                  }}
                              />
                            </div>
                          </Grid>

                          <Grid item xs={3}>
                            <div className="productValueWrapper">
                              <BasicInput
                                  name="effective_date"
                                  type="date"
                                  fullWidth
                                  value={product.effective_date || formattedDate}
                                  onChange={(e) => {
                                    handleEffectiveDateChange(
                                        index,
                                        e.target.value
                                    );
                                  }}
                              />
                              <IconButton
                                  size="small"
                                  onClick={() => {
                                    handleDeletePolicy(index);
                                  }}
                              >
                                <DeleteForeverIcon color={"error"}/>
                              </IconButton>
                            </div>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid item xs={12}>
                      <BasicAddButton
                        startIcon={<AddIcon />}
                        onClick={handleAddPolicy}
                      >
                        Add New Item
                      </BasicAddButton>
                    </Grid>
                  </Grid>
                </ContentWrapper>
            }
          </div>

          <div className={classes.modalFooter}>
            <Typography style={{ height: 'unset !important' }} className="title" variant="h5" color="inherit">
              Deal Value: ${Utils.formatCurrency(dealValue)}
            </Typography>
            <Button
              disabled={isSubmitting}
              className="btnSubmit #346fef"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ConfirmDealModal;
